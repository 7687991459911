import { Link } from "gatsby";
import React from "react";

import ProfilePicture from "./ProfilePicture";

const Header = ({ siteTitle }) => (
  <header className="tc pt4 pt5-ns bg-white">
    <Link to="/">
      <ProfilePicture />
    </Link>
    <h1 className="f4 f3-ns fw7 mid-gray">{siteTitle}</h1>
    <h2 className="f6 mid-gray fw5 ttu tracked">Saigon, Vietnam</h2>
  </header>
);

export default Header;
