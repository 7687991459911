/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "./layout.scss";

import { Sticky, StickyContainer } from "react-sticky";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import Footer from "./Footer";
import Header from "./Header";
import StickyHeader from "./StickyHeader";

const Layout = ({ children, showProgress }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="w-100 bg-white">
      <Header siteTitle={data.site.siteMetadata.title} />
      <StickyContainer className="pt4 pt5-ns">
        <Sticky disableCompensation>
          {({ style, isSticky }) => (
            <div style={style} className="z-max">
              {isSticky && <StickyHeader showProgress={showProgress} />}
            </div>
          )}
        </Sticky>
        <main className="mw7 center">{children}</main>
        <Footer />
      </StickyContainer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
