/* eslint-env browser */
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

import ProfilePicture from "./ProfilePicture";
import usePrevious from "./usePrevious";

const BaseStickyHeader = ({ progress }) => {
  const [isShowing, setIsShowing] = useState(false);
  const prevProgress = usePrevious(progress);

  useEffect(() => {
    if (progress > prevProgress) {
      setIsShowing(false);
    } else {
      setIsShowing(true);
    }
  }, [progress]);

  return (
    <header
      className="tc bg-white "
      style={{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 5px 20px 0px",
      }}
    >
      <div className="bg-dark-blue" style={{ height: "4px", width: `${progress}%` }} />
      {isShowing && (
        <div className="pv2">
          <Link to="/">
            <ProfilePicture />
          </Link>
        </div>
      )}
    </header>
  );
};

const StickyHeader = ({ showProgress }) => {
  if (showProgress) {
    const [progress, setProgress] = useState(0);
    useEffect(() => {
      const handleScroll = () => {
        const docElement = document.documentElement;
        const offset = docElement.scrollTop + window.innerHeight - 500;
        const height = docElement.offsetHeight - 500;

        setProgress((offset / height) * 100);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", () => handleScroll);
      };
    });
    return <BaseStickyHeader progress={progress} />;
  }
  return <BaseStickyHeader progress={0} />;
};

export default StickyHeader;
